













import { Component, Vue } from 'vue-property-decorator'

import AddPersonToServiceForm from '@/partials/forms/addPersonToServiceForm.vue'

@Component({
  components: {
    AddPersonToServiceForm
  }
})
export default class UpdateServiceChief extends Vue {
  person: ServicePerson = 'chief'
}
